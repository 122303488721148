import React from 'react';

import Image from 'react-bootstrap/Image';
import Tercera1994 from './Torneos/Tercera1994';
import Tercera1995 from './Torneos/Tercera1995';
import Tercera1996 from './Torneos/Tercera1996';
import Tercera1997 from './Torneos/Tercera1997';
import PrimeraB2000 from './Torneos/PrimeraB2000';
import PrimeraB2001 from './Torneos/PrimeraB2001';
import PrimeraB2002 from './Torneos/PrimeraB2002';
import PrimeraB2013 from './Torneos/PrimeraB2013';
import PrimeraB2023 from './Torneos/PrimeraB2023';
import PrimeraB2024 from './Torneos/PrimeraB2024';
import Apertura2003 from './Torneos/Apertura2003';
import PrimeraB1998 from './Torneos/PrimeraB1998';
import PrimeraB1999 from './Torneos/PrimeraB1999';
import PrimeraB2021 from './Torneos/PrimeraB2021';
import PrimeraB2022 from './Torneos/PrimeraB2022';
import Clausura2003 from './Torneos/Clausura2003';
import Clausura2016 from './Torneos/Clausura2016';
import Clausura2017 from './Torneos/Clausura2017';
import Transicion2017 from './Torneos/Transicion2017';
import SuperCopa2015 from './Torneos/SuperCopa2015';
import PreSudamericana2003 from './Torneos/PreSudamericana2003';
import Apertura2004 from './Torneos/Apertura2004';
import Apertura2005 from './Torneos/Apertura2005';
import Apertura2006 from './Torneos/Apertura2006';
import Apertura2007 from './Torneos/Apertura2007';
import Apertura2008 from './Torneos/Apertura2008';
import Apertura2009 from './Torneos/Apertura2009';
import Apertura2011 from './Torneos/Apertura2011';
import Apertura2012 from './Torneos/Apertura2012';
import Apertura2013 from './Torneos/Apertura2013';
import Apertura2014 from './Torneos/Apertura2014';
import Apertura2015 from './Torneos/Apertura2015';
import Apertura2016 from './Torneos/Apertura2016';
import Sudamericana2016 from './Torneos/Sudamericana2016';
import Sudamericana2015 from './Torneos/Sudamericana2015';
import Libertadores2004 from './Torneos/Libertadores2004';
import Libertadores2018 from './Torneos/Libertadores2018';
import Libertadores2019 from './Torneos/Libertadores2019';
import Campeonato2010 from './Torneos/Campeonato2010';
import Campeonato2018 from './Torneos/Campeonato2018';
import Campeonato2019 from './Torneos/Campeonato2019';
import Campeonato2020 from './Torneos/Campeonato2020';
import PreSudamericana2004 from './Torneos/PreSudamericana2004';
import Sudamericana2004 from './Torneos/Sudamericana2004';
import Clausura2004 from './Torneos/Clausura2004';
import Clausura2005 from './Torneos/Clausura2005';
import Clausura2006 from './Torneos/Clausura2006';
import Clausura2007 from './Torneos/Clausura2007';
import Clausura2008 from './Torneos/Clausura2008';
import Clausura2009 from './Torneos/Clausura2009';
import Clausura2011 from './Torneos/Clausura2011';
import Clausura2012 from './Torneos/Clausura2012';
import Clausura2014 from './Torneos/Clausura2014';
import Clausura2015 from './Torneos/Clausura2015';
import CopaChile2023 from './Torneos/CopaChile2023';
import CopaChile2022 from './Torneos/CopaChile2022';
import CopaChile2021 from './Torneos/CopaChile2021';
import CopaChile2019 from './Torneos/CopaChile2019';
import CopaChile2018 from './Torneos/CopaChile2018';
import CopaChile2017 from './Torneos/CopaChile2017';
import CopaChile2016 from './Torneos/CopaChile2016';
import CopaChile2015 from './Torneos/CopaChile2015';
import CopaChile2011 from './Torneos/CopaChile2011';
import CopaChile2010 from './Torneos/CopaChile2010';
import CopaChile2009 from './Torneos/CopaChile2009';
import CopaChile2008 from './Torneos/CopaChile2008';
import PreSudamericana2009 from './Torneos/PreSudamericana2009';
import PreLibertadores2018 from './Torneos/PreLibertadores2018';
import Promocion2008 from './Torneos/Promocion2008';
import Promocion2010 from './Torneos/Promocion2010';
import Promocion2012 from './Torneos/Promocion2012';
import Promocion2020 from './Torneos/Promocion2020';
import CopaChile2012 from './Torneos/CopaChile2012';
import CopaChile2013 from './Torneos/CopaChile2013';
import CopaChile2014 from './Torneos/CopaChile2014';
import CopaChile2024 from './Torneos/CopaChile2024';
import CopaChile2025 from './Torneos/CopaChile2025';
class Torneo extends React.Component {

  constructor(props){
    super(props);
        this.comeBack = this.comeBack.bind(this);
    this.state = {
      path: this.props.path,
      ctype: this.props.ctype,
      torneo: this.props.torneo

    };
  }

  componentDidMount() {
	}




comeBack(){
this.setState({path:this.state.path});
}



render(){
  return(

	<div style={{textAlign: 'center' }}>
      <div style={{ height: '30px' }}></div>
        <div className="mytitlepartidos"> {this.state.torneo} </div>
      <div style={{textAlign: 'center' }}>
        <div style={{ height: '20px' }}></div>
      <Image  className="myescudo-away-main"  src={'/images/escudos/udeconce.png'}/>
      </div>

      <div style={{ height: '50px' }}></div>
		{this.state.path === '1994' && <Tercera1994/>}
		{this.state.path === '1995' && <Tercera1995/>}
		{this.state.path === '1996' && <Tercera1996/>}
		{this.state.path === '1997' && <Tercera1997/>}
		{this.state.path === '1998' && <PrimeraB1998/>}
		{this.state.path === '1999' && <PrimeraB1999/>}
                {this.state.path === '2000' && <PrimeraB2000/>}
		{this.state.path === '2001' && <PrimeraB2001/>}
		{this.state.path === '2013' && <PrimeraB2013/>}
		{this.state.path === '2002' && <PrimeraB2002/>}
		{this.state.path === '2024' && <PrimeraB2024/>}
		{this.state.path === '2023' && <PrimeraB2023/>}
		{this.state.path === '2022' && <PrimeraB2022/>}
		{this.state.path === '2021' && <PrimeraB2021/>}
		{this.state.path === 'a2003' && <Apertura2003/>}
                {this.state.path === 'c2003' && <Clausura2003/>}
                {this.state.path === 'ps2003' && <PreSudamericana2003/>}
		{this.state.path === 'a2004' && <Apertura2004/>}
		{this.state.path === 'lib2018' && <Libertadores2018/>}
		{this.state.path === 'lib2019' && <Libertadores2019/>}
		{this.state.path === 'lib2004' && <Libertadores2004/>}
		{this.state.path === 'ps2004' && <PreSudamericana2004/>}
		{this.state.path === 'pl2018' && <PreLibertadores2018/>}
		{this.state.path === 'c2004' && <Clausura2004/>}
		{this.state.path === 'sud2004' && <Sudamericana2004/>}
		{this.state.path === 'sud2015' && <Sudamericana2015/>}
		{this.state.path === 'sud2016' && <Sudamericana2016/>}
		{this.state.path === 'a2005' && <Apertura2005/>}
                {this.state.path === 'c2005' && <Clausura2005/>}
		{this.state.path === 'c2006' && <Clausura2006/>}
		{this.state.path === 'a2006' && <Apertura2006/>}
		{this.state.path === 'a2007' && <Apertura2007/>}
	        {this.state.path === 'c2007' && <Clausura2007/>}
                {this.state.path === 'a2008' && <Apertura2008/>}
		{this.state.path === 'sc2015' && <SuperCopa2015/>}
		{this.state.path === 'c2008' && <Clausura2008/>}
		{this.state.path === 'a2009' && <Apertura2009/>}
		{this.state.path === 'c2009' && <Clausura2009/>}
		{this.state.path === '2010' && <Campeonato2010/>}
		{this.state.path === '2018' && <Campeonato2018/>}
		{this.state.path === '2019' && <Campeonato2019/>}
		{this.state.path === '2020' && <Campeonato2020/>}
		{this.state.path === 'a2011' && <Apertura2011/>}
                {this.state.path === 'c2011' && <Clausura2011/>}
		{this.state.path === 'a2012' && <Apertura2012/>}
                {this.state.path === 'c2012' && <Clausura2012/>}
		{this.state.path === 'a2013' && <Apertura2013/>}
	        {this.state.path === 'a2014' && <Apertura2014/>}
		{this.state.path === 'c2014' && <Clausura2014/>}
		{this.state.path === 'a2015' && <Apertura2015/>}
		{this.state.path === 'c2015' && <Clausura2015/>}
		{this.state.path === 'c2016' && <Clausura2016/>}
		{this.state.path === 'c2017' && <Clausura2017/>}
		{this.state.path === 'a2016' && <Apertura2016/>}
		{this.state.path === 't2017' && <Transicion2017/>}
                {this.state.path === 'chile25' && <CopaChile2025/>}
		{this.state.path === 'chile24' && <CopaChile2024/>}
		{this.state.path === 'chile23' && <CopaChile2023/>}
		{this.state.path === 'chile22' && <CopaChile2022/>}
                {this.state.path === 'chile21' && <CopaChile2021/>}
                {this.state.path === 'chile19' && <CopaChile2019/>}
                {this.state.path === 'chile18' && <CopaChile2018/>}
                {this.state.path === 'chile17' && <CopaChile2017/>}
                {this.state.path === 'chile16' && <CopaChile2016/>}
                {this.state.path === 'chile15' && <CopaChile2015/>}
		{this.state.path === 'chile14' && <CopaChile2014/>}
		{this.state.path === 'chile13' && <CopaChile2013/>}
		{this.state.path === 'chile12' && <CopaChile2012/>}
                {this.state.path === 'chile10' && <CopaChile2010/>}
                {this.state.path === 'chile09' && <CopaChile2009/>}
                {this.state.path === 'chile08' && <CopaChile2008/>}
		{this.state.path === 'chile11' && <CopaChile2011/>}
		{this.state.path === 'ps2009' && <PreSudamericana2009/>}
		{this.state.path === 'pr2008' && <Promocion2008/>}
		{this.state.path === 'pr2010' && <Promocion2010/>}
		{this.state.path === 'pr2012' && <Promocion2012/>}
		{this.state.path === 'pr2020' && <Promocion2020/>}
        <div style={{ height: '70px' }}></div>

     </div>


  )
}

}
export default Torneo;
