import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import AllMatch from './AllMatch';
import Button from 'react-bootstrap/Button';

class Partidos extends React.Component {

  constructor(props){
    super(props);
   this.indexList = ['1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', 
                     '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025']
   this.yearMap = {'1994':[1,30], '1995': [31,54], '1996':[55,88], '1997':[89, 116], '1998':[117,146], '1999':[147,186], '2000':[187,222], '2001':[223,258],
                   '2002':[259,300], '2003':[301,341], '2004':[342,397], '2005':[398,437], '2006':[438,477], '2007':[478,523], '2008':[524,564], '2009':[565,608],
                   '2010':[609,648], '2011':[649,692], '2012': [693,736], '2013': [737,777], '2014':[778,823], '2015':[824,875], '2016':[876,910], '2017': [911,944],
                   '2018': [945,978], '2019': [979,1010], '2020':[1011, 1036], '2021': [1037,1076], '2022': [1077, 1115], '2023': [1116,1152], '2024':[1153,1185], '2025':[1186,1191]}
    this.state = {
      showCards: false,
      matches: [],
      index: 31,
      start: 1186,
      end: 1191,
      total: 1191
    };
  }

nextGroup(init, fin){
	if(this.state.index + 1 < this.indexList.length){
	let s = this.yearMap[this.indexList[this.state.index + 1]][0]
	let f = this.yearMap[this.indexList[this.state.index + 1]][1]
	this.setState({index: this.state.index + 1, start: s, end: f} , () => {
		this.getPartidos();
	});
}
}

previousGroup(init, fin){
	if(this.state.index - 1 >= 0){
        let s = this.yearMap[this.indexList[this.state.index - 1]][0]
        let f = this.yearMap[this.indexList[this.state.index - 1]][1]
        this.setState({index: this.state.index - 1, start: s, end: f} , () => {
                this.getPartidos();
        });
	}
}


  componentDidMount(){

     this.getPartidos();

  }


  getPartidos(){
    fetch("https://www.registrosudec.com:5000/lista_allpartidos", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      start: this.state.start, end: this.state.end
    })
    }).then(res => res.json())
  .then(
    (result) => {
     
     this.setState({matches: result['ids']})
    },
    (error) => {
      console.log(error);
      this.setState({matches: []})
    }
  )
  }


  render(){

	console.log(this.state.matches);


    return(

	<Container>
      <div style={{textAlign: 'center' }}>
      <div style={{ height: '20px' }}></div>
    <span class="mytitlepartidos"> Lista de Partidos </span>
 <div style={{ height: '10px' }}></div>
      <div style={{textAlign: 'center' }}>
      <Image  className="myescudo-away-main" data-tip data-for={'udeconce-escudo'} src={'/images/escudos/udeconce.png'}/>
      </div>

        <div style={{ height: '70px' }}></div>
<Button onClick={() => this.previousGroup(this.state.start, this.state.end)} variant="dark">{'<'}</Button>
{"  "+this.indexList[this.state.index]+" "}
<Button onClick={() => this.nextGroup(this.state.start, this.state.end)} variant="dark">{'>'}</Button>

<div style={{ height: '10px' }}></div>
	<Table responsive variant="dark"  striped bordered hover size="sm">
     <thead>
     <tr>
       <th>Fecha</th>
       <th>Local</th>
       <th>vs.</th>
       <th>Visita</th>
       <th>Estadio</th>
       <th>TV</th>
	<th>Video</th>
     </tr>
   </thead>

     <tbody>

    {this.state.matches.map((i) => {
                   return ( <AllMatch shortname={i['shortname']} fixture={i['fixture']}
                                state={i['state']} rivalName={i['rivalName']}
	    		        goal_home={i['goal_home']} id={i['id']}
				goal_away={i['goal_away']} stadium={i['stadium']}
			        tv={i['tv']} video={i['video']}team_image={"/images/escudos/"+i['shortname']+".png"} ></AllMatch>   )
            })}
            </tbody>


         </Table>
<Button onClick={() => this.previousGroup(this.state.start, this.state.end)} variant="dark">{'<'}</Button>
{"  "+this.indexList[this.state.index]+" "}
<Button onClick={() => this.nextGroup(this.state.start, this.state.end)} variant="dark">{'>'}</Button>
<div style={{ height: '70px' }}></div>
	</div>


</Container>


    )

}
}
export default Partidos;
