import React from 'react';
import Button from 'react-bootstrap/Button';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';




class SummaryTournament extends React.Component {

  constructor(props){
    super(props);
	this.handleShow = this.handleShow.bind(this);
        this.comeBack = this.comeBack.bind(this);
    this.state = {
      rivalName: '',
      path: this.props.path,
      all: [{'name':'Primera División', 'pj': 616, 'pg':223, 'pe': 169, 'pp': 224, 'gf': 866, 'gc':849, 'pts':838, 'link': 'primera'},
             {'name':'Primera B', 'pj': 324, 'pg':123, 'pe': 82, 'pp': 119, 'gf': 443, 'gc':424, 'pts':369, 'link': 'primerab'},
	     {'name':'Tercera División', 'pj': 116, 'pg':57, 'pe': 35, 'pp': 24, 'gf': 215, 'gc':120, 'pts':191, 'link': 'tercera'},
	     {'name':'Copa Chile', 'pj': 89, 'pg':41, 'pe': 22, 'pp': 26, 'gf': 139, 'gc':98, 'pts':145, 'link': 'copachile'},
	     {'name':'SuperCopa', 'pj': 1, 'pg':0, 'pe': 0, 'pp': 1, 'gf': 1, 'gc':2, 'pts':0, 'link': 'supercopa'},
             {'name':'Promoción', 'pj': 7, 'pg':4, 'pe': 0, 'pp': 3, 'gf': 11, 'gc':8, 'pts':12, 'link': 'promocion'},
             {'name':'Pre Libertadores', 'pj': 2, 'pg':2, 'pe': 0, 'pp': 0, 'gf': 3, 'gc':1, 'pts':6, 'link': 'prelibertadores'},
             {'name':'Pre Sudamericana', 'pj': 8, 'pg':3, 'pe': 2, 'pp': 3, 'gf': 12, 'gc':11, 'pts':11, 'link': 'presudamericana'},
             {'name':'Copa Libertadores', 'pj': 14, 'pg':1, 'pe': 5, 'pp': 8, 'gf': 16, 'gc':33, 'pts':8, 'link': 'libertadores'},
             {'name':'Copa Sudamericana', 'pj': 8, 'pg':3, 'pe': 1, 'pp': 4, 'gf': 9, 'gc':13, 'pts':10, 'link': 'sudamericana'}],
      detail: [{'name':'Apertura 2003', 'pj':21, 'pg':9, 'pe': 4, 'pp': 8, 'gf': 43, 'gc':33, 'pts':31, 'link': 'a2003', 'path':'primera', 'pos':3},
		{'name':'Clausura 2003', 'pj':19, 'pg':11, 'pe': 6, 'pp': 2, 'gf': 46, 'gc':22, 'pts':39, 'link': 'c2003', 'path':'primera', 'pos':5},
		{'name':'Apertura 2004', 'pj':21, 'pg':11, 'pe': 6, 'pp': 4, 'gf': 41, 'gc':20, 'pts':39, 'link': 'a2004', 'path':'primera', 'pos':5},
                {'name':'Clausura 2004', 'pj':19, 'pg':8, 'pe': 6, 'pp': 5, 'gf': 23, 'gc':17, 'pts':30, 'link': 'c2004', 'path':'primera', 'pos':9},
		{'name':'Apertura 2005', 'pj':19, 'pg':6, 'pe': 4, 'pp': 9, 'gf': 23, 'gc':31, 'pts':22, 'link': 'a2005', 'path':'primera', 'pos':14},
                {'name':'Clausura 2005', 'pj':21, 'pg':8, 'pe': 4, 'pp': 9, 'gf': 28, 'gc':30, 'pts':28, 'link': 'c2005', 'path':'primera', 'pos':7},
		{'name':'Apertura 2006', 'pj':22, 'pg':10, 'pe': 6, 'pp': 6, 'gf': 37, 'gc':31, 'pts':36, 'link': 'a2006', 'path':'primera', 'pos':4},
                {'name':'Clausura 2006', 'pj':18, 'pg':7, 'pe': 4, 'pp': 7, 'gf': 34, 'gc':30, 'pts':25, 'link': 'c2006', 'path':'primera', 'pos':10},
		{'name':'Apertura 2007', 'pj':20, 'pg':4, 'pe': 5, 'pp': 11, 'gf': 25, 'gc':33, 'pts':17, 'link': 'a2007', 'path':'primera', 'pos':17},
                {'name':'Clausura 2007', 'pj':26, 'pg':11, 'pe': 8, 'pp': 7, 'gf': 40, 'gc':32, 'pts':41, 'link': 'c2007', 'path':'primera', 'pos':2},
		{'name':'Apertura 2008', 'pj':19, 'pg':6, 'pe': 3, 'pp': 10, 'gf': 27, 'gc':33, 'pts':21, 'link': 'a2008', 'path':'primera', 'pos':16},
                {'name':'Clausura 2008', 'pj':18, 'pg':5, 'pe': 6, 'pp': 7, 'gf': 32, 'gc':32, 'pts':21, 'link': 'c2008', 'path':'primera', 'pos':15},
		{'name':'Apertura 2009', 'pj':17, 'pg':6, 'pe': 5, 'pp': 6, 'gf': 24, 'gc':25, 'pts':23, 'link': 'a2009', 'path':'primera', 'pos':11},
                {'name':'Clausura 2009', 'pj':19, 'pg':8, 'pe': 3, 'pp': 8, 'gf': 27, 'gc':29, 'pts':27, 'link': 'c2009', 'path':'primera', 'pos':6},
		{'name':'Campeonato 2010', 'pj':34, 'pg':9, 'pe':11, 'pp': 14, 'gf': 39, 'gc':50, 'pts':38, 'link': '2010', 'path':'primera', 'pos':15},
		{'name':'Apertura 2011', 'pj':17, 'pg':6, 'pe': 2, 'pp': 9, 'gf': 24, 'gc':26, 'pts':20, 'link': 'a2011', 'path':'primera', 'pos':13},
                {'name':'Clausura 2011', 'pj':17, 'pg':4, 'pe': 7, 'pp': 6, 'gf': 20, 'gc':21, 'pts':19, 'link': 'c2011', 'path':'primera', 'pos':14},
		{'name':'Apertura 2012', 'pj':17, 'pg':5, 'pe': 6, 'pp': 6, 'gf': 23, 'gc':27, 'pts':21, 'link': 'a2012', 'path':'primera', 'pos':10},
                {'name':'Clausura 2012', 'pj':17, 'pg':3, 'pe': 6, 'pp': 8, 'gf': 17, 'gc':28, 'pts':15, 'link': 'c2012', 'path':'primera', 'pos':16},
		{'name':'Apertura 2013', 'pj':17, 'pg':5, 'pe': 7, 'pp': 5, 'gf': 21, 'gc':19, 'pts':22, 'link': 'a2013', 'path':'primera', 'pos':12},
                {'name':'Clausura 2014', 'pj':19, 'pg':7, 'pe': 7, 'pp': 5, 'gf': 22, 'gc':20, 'pts':28, 'link': 'c2014', 'path':'primera', 'pos':4},
		{'name':'Apertura 2014', 'pj':17, 'pg':4, 'pe': 6, 'pp': 7, 'gf': 18, 'gc':23, 'pts':18, 'link': 'a2014', 'path':'primera', 'pos':12},
                {'name':'Clausura 2015', 'pj':17, 'pg':8, 'pe': 3, 'pp': 6, 'gf': 27, 'gc':26, 'pts':27, 'link': 'c2015', 'path':'primera', 'pos':6},
		{'name':'Apertura 2015', 'pj':17, 'pg':9, 'pe': 1, 'pp': 7, 'gf': 30, 'gc':22, 'pts':28, 'link': 'a2015', 'path':'primera', 'pos':3},
                {'name':'Clausura 2016', 'pj':15, 'pg':8, 'pe': 1, 'pp': 6, 'gf': 22, 'gc':24, 'pts':25, 'link': 'c2016', 'path':'primera', 'pos':5},
		{'name':'Apertura 2016', 'pj':15, 'pg':4, 'pe': 2, 'pp': 9, 'gf': 15, 'gc':25, 'pts':14, 'link': 'a2016', 'path':'primera', 'pos':16},
                {'name':'Clausura 2017', 'pj':15, 'pg':6, 'pe': 6, 'pp': 3, 'gf': 16, 'gc':13, 'pts':24, 'link': 'c2017', 'path':'primera', 'pos':3},
		{'name':'Transición 2017', 'pj':15, 'pg':3, 'pe': 8, 'pp': 4, 'gf': 16, 'gc':15, 'pts':17, 'link': 't2017', 'path':'primera', 'pos':10},
		{'name':'Campeonato 2018', 'pj':30, 'pg':18, 'pe': 4, 'pp': 8, 'gf': 45, 'gc':31, 'pts':58, 'link': '2018', 'path':'primera', 'pos':2},
                {'name':'Campeonato 2019', 'pj':24, 'pg':5, 'pe': 8, 'pp': 11, 'gf': 23, 'gc':35, 'pts':23, 'link': '2019', 'path':'primera', 'pos':16},
		{'name':'Campeonato 2020', 'pj':34, 'pg':9, 'pe': 14, 'pp': 11, 'gf': 38, 'gc':46, 'pts':41, 'link': '2020', 'path':'primera', 'pos':14},

                {'name':'Primera B 1998', 'pj':30, 'pg':16, 'pe': 4, 'pp': 10, 'gf': 59, 'gc':44, 'pts':52, 'link': '1998', 'path':'primerab', 'pos':5},
		{'name':'Primera B 1999', 'pj':40, 'pg':14, 'pe': 10, 'pp': 16, 'gf': 66, 'gc':64, 'pts':62, 'link': '1999', 'path':'primerab', 'pos':9},
		{'name':'Primera B 2000', 'pj':36, 'pg':13, 'pe': 6, 'pp': 17, 'gf': 38, 'gc':48, 'pts':49, 'link': '2000', 'path':'primerab', 'pos':10},
		{'name':'Primera B 2001', 'pj':36, 'pg':13, 'pe': 7, 'pp': 16, 'gf': 43, 'gc':48, 'pts':50, 'link': '2001', 'path':'primerab', 'pos':9},
		{'name':'Primera B 2002', 'pj':42, 'pg':20, 'pe': 12, 'pp': 10, 'gf': 72, 'gc':49, 'pts':72, 'link': '2002', 'path':'primerab', 'pos':2},
		{'name':'Primera B 2013', 'pj':16, 'pg':8, 'pe': 6, 'pp': 2, 'gf': 21, 'gc':7, 'pts':30, 'link': '2013', 'path':'primerab', 'pos':1},
		{'name':'Primera B 2021', 'pj':30, 'pg':7, 'pe': 12, 'pp': 11, 'gf': 32, 'gc':37, 'pts':33, 'link': '2021', 'path':'primerab', 'pos':14},
		{'name':'Primera B 2022', 'pj':34, 'pg':12, 'pe': 11, 'pp': 11, 'gf': 43, 'gc':39, 'pts':47, 'link': '2022', 'path':'primerab', 'pos':6},
		{'name':'Primera B 2023', 'pj':30, 'pg':9, 'pe': 7, 'pp': 14, 'gf': 36, 'gc':49, 'pts':34, 'link': '2023', 'path':'primerab', 'pos':13},
		{'name':'Primera B 2024', 'pj':30, 'pg':11, 'pe': 7, 'pp': 12, 'gf': 33, 'gc':39, 'pts':40, 'link': '2024', 'path':'primerab', 'pos':9},

		{'name':'Tercera División 1994', 'pj':30, 'pg':15, 'pe': 8, 'pp': 7, 'gf': 71, 'gc':37, 'pts':38, 'link': '1994', 'path':'tercera', 'pos':7},
                {'name':'Tercera División 1995', 'pj':24, 'pg':12, 'pe': 6, 'pp': 6, 'gf': 47, 'gc':26, 'pts':42, 'link': '1995', 'path':'tercera', 'pos':17},
                {'name':'Tercera División 1996', 'pj':34, 'pg':16, 'pe': 12, 'pp': 6, 'gf': 53, 'gc':30, 'pts':60, 'link': '1996', 'path':'tercera', 'pos':2},
                {'name':'Tercera División 1997', 'pj':28, 'pg':14, 'pe': 9, 'pp': 5, 'gf': 44, 'gc':27, 'pts':51, 'link': '1997', 'path':'tercera', 'pos':1},

		{'name':'Copa Chile 2008-09', 'pj':5, 'pg':3, 'pe': 2, 'pp': 0, 'gf': 8, 'gc':3, 'pts':11, 'link': 'chile08', 'path':'copachile', 'pos':1},
		{'name':'Copa Chile 2009', 'pj':4, 'pg':1, 'pe': 2, 'pp': 1, 'gf': 4, 'gc':3, 'pts':5, 'link': 'chile09', 'path':'copachile', 'pos':4},
               {'name':'Copa Chile 2010', 'pj':4, 'pg':2, 'pe': 0, 'pp': 2, 'gf': 8, 'gc':4, 'pts':6, 'link': 'chile10', 'path':'copachile', 'pos':25},
               {'name':'Copa Chile 2011', 'pj':10, 'pg':6, 'pe': 1, 'pp': 3, 'gf': 26, 'gc':15, 'pts':19, 'link': 'chile11', 'path':'copachile', 'pos':3},
               {'name':'Copa Chile 2012-13', 'pj':8, 'pg':3, 'pe': 2, 'pp': 3, 'gf': 11, 'gc':13, 'pts':11, 'link': 'chile12', 'path':'copachile', 'pos':14},
               {'name':'Copa Chile 2013-14', 'pj':8, 'pg':2, 'pe': 6, 'pp': 0, 'gf': 7, 'gc':3, 'pts':12, 'link': 'chile13', 'path':'copachile', 'pos':13},
               {'name':'Copa Chile 2014-15', 'pj':13, 'pg':9, 'pe': 2, 'pp': 2, 'gf': 22, 'gc':13, 'pts':29, 'link': 'chile14', 'path':'copachile', 'pos':1},
               {'name':'Copa Chile 2015', 'pj':12, 'pg':5, 'pe': 4, 'pp': 3, 'gf': 17, 'gc':16, 'pts':19, 'link': 'chile15', 'path':'copachile', 'pos':4},
               {'name':'Copa Chile 2016', 'pj':3, 'pg':1, 'pe': 0, 'pp': 2, 'gf': 3, 'gc':2, 'pts':3, 'link': 'chile16', 'path':'copachile', 'pos':16},
               {'name':'Copa Chile 2017', 'pj':2, 'pg':0, 'pe': 0, 'pp': 2, 'gf': 0, 'gc':4, 'pts':0, 'link': 'chile17', 'path':'copachile', 'pos':30},
               {'name':'Copa Chile 2018', 'pj':2, 'pg':0, 'pe': 0, 'pp': 2, 'gf': 1, 'gc':3, 'pts':0, 'link': 'chile18', 'path':'copachile', 'pos':28},
               {'name':'Copa Chile 2019', 'pj':2, 'pg':0, 'pe': 2, 'pp': 0, 'gf': 1, 'gc':1, 'pts':2, 'link': 'chile19', 'path':'copachile', 'pos':23},
               {'name':'Copa Chile 2021', 'pj':1, 'pg':0, 'pe': 0, 'pp': 1, 'gf': 0, 'gc':1, 'pts':0, 'link': 'chile21', 'path':'copachile', 'pos':34},
               {'name':'Copa Chile 2022', 'pj':5, 'pg':3, 'pe': 0, 'pp': 2, 'gf': 16, 'gc':10, 'pts':9, 'link': 'chile22', 'path':'copachile', 'pos':9},
	       {'name':'Copa Chile 2023', 'pj':7, 'pg':5, 'pe': 0, 'pp': 2, 'gf': 12, 'gc':6, 'pts':15, 'link': 'chile23', 'path':'copachile', 'pos':3},
		{'name':'Copa Chile 2024', 'pj':3, 'pg':1, 'pe': 1, 'pp': 1, 'gf': 3, 'gc':1, 'pts':4, 'link': 'chile24', 'path':'copachile', 'pos':24},
		{'name':'Copa Chile 2025', 'pj':0, 'pg':0, 'pe': 0, 'pp': 0, 'gf': 0, 'gc':0, 'pts':0, 'link': 'chile25', 'path':'copachile', 'pos':''},
	       {'name':'SuperCopa 2015', 'pj':1, 'pg':0, 'pe': 0, 'pp': 1, 'gf': 1, 'gc':2, 'pts':0, 'link': 'sc2015', 'path':'supercopa', 'pos':2},

	       {'name':'Promoción 2008', 'pj':2, 'pg':2, 'pe': 0, 'pp': 0, 'gf': 5, 'gc':1, 'pts':6, 'link': 'pr2008', 'path':'promocion', 'pos':1},
	       {'name':'Promoción 2010', 'pj':2, 'pg':2, 'pe': 0, 'pp': 0, 'gf': 5, 'gc':2, 'pts':6, 'link': 'pr2010', 'path':'promocion', 'pos':1},
	       {'name':'Promoción 2012', 'pj':2, 'pg':0, 'pe': 0, 'pp': 2, 'gf': 1, 'gc':4, 'pts':0, 'link': 'pr2012', 'path':'promocion', 'pos':2},
	       {'name':'Promoción 2020', 'pj':1, 'pg':0, 'pe': 0, 'pp': 1, 'gf': 0, 'gc':1, 'pts':0, 'link': 'pr2020', 'path':'promocion', 'pos':2},

	       {'name':'Pre-Libertadores 2018', 'pj':2, 'pg':2, 'pe': 0, 'pp': 0, 'gf': 3, 'gc':1, 'pts':6, 'link': 'pl2018', 'path':'prelibertadores', 'pos':1},
	       
	       {'name':'Pre-Sudamericana 2003', 'pj':1, 'pg':0, 'pe': 0, 'pp': 1, 'gf': 0, 'gc':1, 'pts':0, 'link': 'ps2003', 'path':'presudamericana', 'pos':24},
	       {'name':'Pre-Sudamericana 2004', 'pj':6, 'pg':3, 'pe': 2, 'pp': 1, 'gf': 11, 'gc':7, 'pts':11, 'link': 'ps2004', 'path':'presudamericana', 'pos':1},
               {'name':'Pre-Sudamericana 2009', 'pj':1, 'pg':0, 'pe': 0, 'pp': 1, 'gf': 1, 'gc':3, 'pts':0, 'link': 'ps2009', 'path':'presudamericana', 'pos':2},

	       {'name':'Copa Libertadores 2004', 'pj':6, 'pg':0, 'pe': 2, 'pp': 4, 'gf': 7, 'gc':16, 'pts':2, 'link': 'lib2004', 'path':'libertadores', 'pos':34},
	       {'name':'Copa Libertadores 2018', 'pj':2, 'pg':0, 'pe': 0, 'pp': 2, 'gf': 0, 'gc':6, 'pts':0, 'link': 'lib2018', 'path':'libertadores', 'pos':44},
	       {'name':'Copa Libertadores 2019', 'pj':6, 'pg':1, 'pe': 3, 'pp': 2, 'gf': 9, 'gc':11, 'pts':6, 'link': 'lib2019', 'path':'libertadores', 'pos':23},

	       {'name':'Copa Sudamericana 2004', 'pj':4, 'pg':2, 'pe': 1, 'pp': 1, 'gf': 5, 'gc':5, 'pts':7, 'link': 'sud2004', 'path':'sudamericana', 'pos':12},
		{'name':'Copa Sudamericana 2015', 'pj':2, 'pg':0, 'pe': 0, 'pp': 2, 'gf': 2, 'gc':5, 'pts':0, 'link': 'sud2015', 'path':'sudamericana', 'pos':42},
		{'name':'Copa Sudamericana 2016', 'pj':2, 'pg':1, 'pe': 0, 'pp': 1, 'gf': 2, 'gc':3, 'pts':3, 'link': 'sud2016', 'path':'sudamericana', 'pos':35},
		]


    };
  }

  componentDidMount() {
	}



assignData(){

   var total = {'name':'Total', 'pj': 0, 'pg':0, 'pe': 0, 'pp': 0, 'gf': 0, 'gc':0, 'pts':0};

   if(this.state.path === 'todos'){
	for(let j = 0; j < this.state.all.length; j++){

		total['pj'] = total['pj'] + this.state.all[j]['pj'];
		total['pg'] = total['pg'] + this.state.all[j]['pg'];
		total['pe'] = total['pe'] + this.state.all[j]['pe'];
		total['pp'] = total['pp'] + this.state.all[j]['pp'];
		total['gf'] = total['gf'] + this.state.all[j]['gf'];
		total['gc'] = total['gc'] + this.state.all[j]['gc'];
		total['pts'] = total['pts'] + this.state.all[j]['pts']
	}

	return (
		
		    <tbody>{ this.state.all.map((_type, i) => {
                   return ( _type.pj !== 0 &&  <tr style={{textAlign: 'center' }}>
                               <td ><button className="mynewbutton" onClick={() => this.handleShow(_type.link)}>{_type.name}</button></td>
                               <td >{_type.pj}</td>
                               <td >{_type.pg}</td>
                               <td >{_type.pe}</td>
                               <td >{_type.pp}</td>
                               <td >{_type.gf}</td>
                               <td >{_type.gc}</td>
                               <td >{_type.gf - _type.gc}</td>
                               <td >{_type.pts}</td>
                                 </tr>

                     )
            })
		}

	<td> {total.name}</td><td >{total.pj}</td>
                      <td>{total.pg}</td><td >{total.pe}</td>
                      <td >{total.pp}</td><td >{total.gf}</td><td >{total.gc}</td>
                               <td >{total.gf - total.gc}</td>
                               <td >{total.pts}</td>

	</tbody>

	)

   }
	else{

	total = {'name':'Total', 'pj': 0, 'pg':0, 'pe': 0, 'pp': 0, 'gf': 0, 'gc':0, 'pts':0};

        for(let j = 0; j < this.state.detail.length; j++){
		if(this.state.detail[j]['path'] === this.state.path){

                total['pj'] = total['pj'] + this.state.detail[j]['pj'];
                total['pg'] = total['pg'] + this.state.detail[j]['pg'];
                total['pe'] = total['pe'] + this.state.detail[j]['pe'];
                total['pp'] = total['pp'] + this.state.detail[j]['pp'];
                total['gf'] = total['gf'] + this.state.detail[j]['gf'];
                total['gc'] = total['gc'] + this.state.detail[j]['gc'];
                total['pts'] = total['pts'] + this.state.detail[j]['pts'];
	       }
        }

           return(<tbody>
	
	    { this.state.detail.map((_type, i) => {
                   return ( _type.path === this.state.path &&  
                               <tr style={{textAlign: 'center' }}>
			       <td >{_type.pos}</td>
				<td><Link className="link-name" to={"/campeonatos/" + _type.link}> {_type.name}</Link></td>
                               <td >{_type.pj}</td>
                               <td >{_type.pg}</td>
                               <td >{_type.pe}</td>
                               <td >{_type.pp}</td>
                               <td >{_type.gf}</td>
                               <td >{_type.gc}</td>
                               <td >{_type.gf - _type.gc}</td>
                               <td >{_type.pts}</td>
                                 </tr>

                     )
            })
	}
		<td> </td>
	        <td> {total.name}</td><td >{total.pj}</td>
                      <td>{total.pg}</td><td >{total.pe}</td>
                      <td >{total.pp}</td><td >{total.gf}</td><td >{total.gc}</td>
                               <td >{total.gf - total.gc}</td>
                               <td >{total.pts}</td>
	</tbody>
	)

	}

}


handleShow(p){

this.setState({path:p})
console.log(this.state);
}

comeBack(){
this.setState({path:'todos'});
console.log(this.state);
}

setTable(){

var componente = [];

      componente.push(
	<Row className="justify-content-md-center">
    <Col lg={6} xl={6}  >
    <Table variant="dark" striped bordered hover size="sm">
  <thead>
    <tr style={{textAlign: 'center' }}>
	{this.state.path !== 'todos' && <th> Pos </th>}
    <th>Torneo</th>
      <th>PJ</th>
      <th>PG</th>
      <th>PE</th>
      <th>PP</th>
      <th>GF</th>
      <th>GC</th>
      <th>DIF</th>
      <th>PTS</th>
    </tr>
  </thead>
	{ this.assignData() }
  </Table>
  </Col>






  </Row>	)

return componente;

}



render(){

   let componente = this.setTable();
  return(

<div>
{this.state.path !== 'todos' && <div className='back-button'><Button variant="dark" onClick={() => this.comeBack()}> Volver </Button></div>}
{this.state.path !== 'todos' && <div style={{ height: '20px'}}></div>}

	{componente}
</div>
  )
}

}
export default SummaryTournament;
